import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hello, I'm Maia`}</h1>
    <h3>{`Creative Technologist.`}</h3>
    <p>{`Artist, developer, `}<em parentName="p">{`reluctant`}</em>{` entrepreneur... former indie bookseller living in Chicago, Illinois.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      