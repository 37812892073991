import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Wave App" link="/wave" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
    First dive into blockchain development. This application interacts with the test Ethereum network Rin. No actual ether is exchanged.
    </ProjectCard>
    <ProjectCard title="Reach2020" link="https://reach.g2.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
    As Lead Web Developer at G2, I built a custom platform to host our on demand virtual conference using Hubspot.
    </ProjectCard>
    <ProjectCard title="Webflow" link="https://www.maiasingletary.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
    Once upon a time, I built websites on Webflow. Here's the one I created for myself... back in.. well doesn't matter, right?
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      